import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import useEnglish from "../library/useEnglish"
import IndexPage from "../pagesLayouts/IndexPage"
import { IndexPageQuery } from "../types/schema"

export default function IndexEn() {
  useEnglish()

  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "en" } }, slug: { eq: "homepage-en" }) {
        ...WordpressPageFragment
      }
      wpOptions: wpPage(
        language: { slug: { eq: "en" } }
        slug: { eq: "homepage-options-en" }
      ) {
        homepagephotos {
          photoButtonAboutUs {
            ...HomepageImageButtonPhoto
          }
          photoButtonAboutUsText
          photoButtonClasses {
            ...HomepageImageButtonPhoto
          }
          photoButtonClassesText
          photoButtonTeam {
            ...HomepageImageButtonPhoto
          }
          photoButtonTeamText
          headerPhoto {
            ...IndexPageMainDesktopPhoto
          }
          headerPhotoMobile {
            ...IndexPageMainMobilePhoto
          }
          headerPhotoMobileText
          headerPhotoText
        }
      }
    }
  `

  const data = useStaticQuery<IndexPageQuery>(query)

  return <IndexPage data={data} language="en" />
}
